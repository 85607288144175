<template>
    <div id="resume">
        <AutoGenerate/>
    </div>
</template>

<script>
import '@/assets/css/main.css'
import AutoGenerate from '@/components/Content/AutoGenerate.vue';

export default {
    name: 'ResumeView',
    components: {
        AutoGenerate
    }
}
</script>

<style>

</style>